exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-call-for-paper-js": () => import("./../../../src/pages/call-for-paper.js" /* webpackChunkName: "component---src-pages-call-for-paper-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pictures-js": () => import("./../../../src/pages/pictures.js" /* webpackChunkName: "component---src-pages-pictures-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-roundtables-js": () => import("./../../../src/pages/roundtables.js" /* webpackChunkName: "component---src-pages-roundtables-js" */),
  "component---src-pages-sponsoring-js": () => import("./../../../src/pages/sponsoring.js" /* webpackChunkName: "component---src-pages-sponsoring-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

